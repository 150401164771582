header {
  color: var(--color1);
  text-transform: uppercase;
  font-weight: bold;
  height: 40px;
  background-color: var(--color4);
  display: flex;
  align-items: center;
  padding-left: 20px;
  font-family: 'Space and Astronomy';
}
