footer {
    color: var(--color1);
    text-transform: uppercase;
    font-weight: bold;
    height: 40px;
    background-color: var(--color4);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-family: 'Space and Astronomy';
    position: fixed;
    width: 100%;
    bottom: 0;

}